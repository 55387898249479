<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>จัดการ Brand</b></h2>
            <small>
              <span class="text-primary"  style="cursor: pointer;"><b>จัดการลูกค้า</b> ></span>             
              <b>Brand</b>
            </small>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <b-button
                v-if="customerPermission=='create' || customerPermission=='edit'"
                variant="primary"
                class="ml-1 shadow"
                @click="$router.push(`/setting/customer/warranty/sub-company/create`)"
                ><feather-icon icon="PlusCircleIcon" size="14" />
                เพิ่มบริษัท
              </b-button>

              <b-button
                v-if="false"
                variant="outline-dark"
                class="ml-2 shadow"
                @click="$router.push(`/setting/customer/warranty/company?oid=${$route.query.oid}&cid=${$route.query.cid}`)"
                ><feather-icon icon="ArrowLeftIcon" size="14" /> ย้อนกลับ
              </b-button>
            </b-form>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
              show-empty           
              empty-filtered-text="ค้นหาไม่พบ"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(subCompanyName)="row">
                <span
                  @click="
                   $router.push(`/setting/customer/warranty/branch?scid=${row.item.id}&subCompanyName=${row.item.subCompanyName}`)                   
                  "
                  style="text-decoration: underline"
                  >{{ row.item.subCompanyName }}</span
                >
                
                <span style="margin-left:3px"> ({{ showNumberComma(row.item.qty) }} รายการ)</span>
              </template>

              <template #cell(status)="row">
                <center>
                  <span
                    v-if="row.item.status == 1"
                    class="text-primary"
                  >
                    เปิดใช้งาน</span
                  >
                  <span v-else class="text-danger"> ปิด</span>
                </center>
              </template>

              <template #cell(action)="row" v-if="customerPermission=='create' || customerPermission=='edit'">
                <center>
                  <b-button variant="outline-secondary" size="sm" pill @click="$router.push(`/setting/customer/warranty/sub-company/edit?scid=${row.item.id}&subCompanyName=${row.item.subCompanyName}`)">
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button variant="danger" class="ml-1" size="sm" pill @click="deleteCustomerSubCompany(row.item.id)">
                    <feather-icon icon="TrashIcon" class="cursor-pointer" />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BForm,
  BCard,
  BSpinner
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Field from "./field";

//SERVICES
import CustomerService from "@/services/setting/customer";
import WarrantyStockService from "@/services/stock/warranty"
import commaNumber from 'comma-number'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],
      isLoading:false
    };
  },
  computed:{
    customerPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST003-1")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("ST003-1") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    } 
  },


  mounted() {
    this.getCustomerSubCompany();
    
  },
  methods: {
    showNumberComma(number){
        return commaNumber(number)
    },
    async getCustomerSubCompany() {
      this.isLoading = true      
      const result = await CustomerService.GetCustomerSubCompanyListAll("status=all&mode=warranty");
      this.items = result.data.data;
      this.totalRows = this.items.length;
      this.isLoading = false
    },
    async deleteCustomerSubCompany(subCompanyId){
      const isConfirm = confirm("คุณต้องการลบรายการนี้หรือไม่ ?")
      if(!isConfirm) return false;


      const resultCheck = await WarrantyStockService.CheckItemBeforeDelete("subCompanyId", subCompanyId)
      if(resultCheck.data.hasData){
        this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `บริษัท`,
              icon: "CheckIcon",
              variant: "danger",
              text: `ไม่สามารถลบได้ เนื่องจากรายการนี้มีการใช้งานจากส่วนอื่นแล้ว`,
            },
          });

          return
      }

      const result = await CustomerService.DeleteCustomerSubCompany(subCompanyId);
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `บริษัท`,
              icon: "CheckIcon",
              variant: "success",
              text: `ลบรายการเรียบร้อย`,
            },
          });
          this.getCustomerSubCompany()         
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `บริษัท`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการลบรายการ`,
            },
          });
        }
      
    },    
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
