export default [
  { key: "no", label: "ลำดับที่", sortable: true , thStyle:{color:"white",width: "6% !important"}},
  {
    key: "subCompanyName",
    label: "ชื่อบริษัท",
    thStyle:{color:"white"}
  },  
  {
    key: "status",
    label: "สถานะ",
    thStyle:{color:"white" ,  width: "8% !important" , textAlign: "center"}
  }, 
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "12% !important", textAlign: "center", color:"white" },
  },
];
